<template>
  <div class="manual">
    <h1>命令指南</h1>
    <h3>公会管理命令</h3>
    <ul>
      <li>建会日服<small>(+公会名)</small></li>
      <li>建会台服<small>(+公会名)</small></li>
      <li>建会B服<small>(+公会名)</small></li>
    </ul>
    <h3>成员管理命令</h3>
    <ul>
      <li>入会<small>(+昵称)</small></li>
      <li>退会<small>(+@qq)</small></li>
      <li>查看成员</li>
      <li>一键入会</li>
      <li>清空成员</li>
    </ul>
    <h3>报刀命令</h3>
    <ul>
      <li>1报刀<small>(+伤害数字)</small></li>
      <li>1补时<small>(+伤害数字)</small></li>
      <li>1收尾</li>
      <li>1补时收尾</li>
      <li>1滑刀</li>
      <li>删刀<small>(+记录编号)</small></li>
      <li>撤销</li>
    </ul>
    <h3>战斗队列命令</h3>
    <ul>
      <li>我进1了</li>
      <li>我不进了</li>
      <li>800w 002s</li>
      <li>SL</li>
      <li>查SL</li>
      <li>1挂树</li>
      <li>查树</li>
    </ul>
    <h3>预约命令</h3>
    <ul>
      <li>预约1</li>
      <li>取消1</li>
      <li>查预约1</li>
      <li>清空1</li>
    </ul>
    <h3>进度管理命令</h3>
    <ul>
      <li>进度</li>
      <li>查1</li>
      <li>调整进度 R1 B1 600w <small>(R1=1周目 B1=Boss1)</small></li>
    </ul>
    <h3>统计命令</h3>
    <ul>
      <li>查刀</li>
      <li>查0/1/2/3刀</li>
      <li>出刀记录<small>(+@qq)</small></li>
    </ul>
  </div>
</template>

<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #5ac9eb;
}
</style>
